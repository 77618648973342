<template>
    <!--  Content Start -->
    <div class="sabc_audio_outer">
        <div class="container">
           <div>
              <!-- <h1 v-if="radioStation == 'radio2000xtra'">Radio Station - Radio 2000 Xtra</h1> -->
              <h1 v-if="radioStation">Radio Station {{radioStation.toUpperCase()}}</h1>
              <h1 v-else>Radio No Found.</h1>
              <div v-html="channelHTML" class="player-container">
              </div>
           </div>
        </div>
     </div>
    <!--  Content end -->
</template>

<script>

    export default {
        name: 'radio-live-stream',
        data() {
            return {
               // channelHTML: '<td-player id="td-player" type="bar" autostart="false" primarycolor="#aaaaaa" secondarycolor="#000000" defaultcoverart="//broadsmart-eventsmedia.s3.amazonaws.com/5FM_Logo.webp" station="'+this.$route.params.channel.toUpperCase()+'" buybtnvisible="false" onappready="appReady"></td-player>',
               radioStation: this.$route.params.channel,
               channelHTML: '',
               channelLogoList: {
                  'metrofm':'/public/channel/radio-player-metrofm-2.jpg',
                  'ligwalagwala':'/public/channel/radio-player-ligwalagwala-2.jpg',
                  'umhlobowenene':'/public/channel/radio-player-umhlobo-3.jpg',
                  'munganalonene':'/public/channel/radio-player-mughana-4.jpg',
                  'phalaphala':'/public/channel/radio-player-phalaphala.jpg',
                  'radio2000':'/public/channel/radio-player-radio2000-5.jpg',
                  'thobela':'/public/channel/radio-player-thobela-7.jpg',
                  'ukhozifm':'/public/channel/radio-player-ukhozi-8.jpg',
                  'trufm':'/public/channel/radio-player-trufm-9.jpg',
                  'lesedi':'/public/channel/radio-player-lesedi-10.jpg',
                  'safm':'/public/channel/radio-player-safm-11.jpg',
                  'motsweding':'/public/channel/radio-player-motsweding-12.jpg',
                  'ikwekwezi':'/public/channel/radio-player-lkwekwezi.jpg',
                //   'radio2000xtra':'/public/channel/radio-player-radio2000-5.jpg',
               }
            }
        },
        components: {},
        mounted() {  
            let tag = document.createElement("script");
            tag.setAttribute("src", "//widgets.listenlive.co/1.0/tdwidgets.min.js");
            // tag.defer = true;
            this.scriptEle = document.head.appendChild(tag);
            let channelLogo = '/public/images/logo.png';
            if(this.channelLogoList[this.radioStation] !== undefined){
               channelLogo = this.channelLogoList[this.radioStation]
               this.channelHTML = '<td-player id="td-player" type="bar" autostart="false" primarycolor="#aaaaaa" secondarycolor="#000000" defaultcoverart="'+channelLogo+'" station="'+this.radioStation+'" buybtnvisible="false" onappready="appReady" trackingparameters="{\'dist\':\'sabc-web-sport\'}"></td-player>';
            }else{
                this.radioStation = '';
            }

        },
       watch: {
       },
      serverPrefetch() {
      },
      beforeRouteLeave: function(to, from, next) {
         // Stop radio channel before move or close
         let target = document.querySelector("#td-player-bar__nowplaying__cover-art__media-controls--pause"); 
         if(target){ 
            target.click()
         }
         next();
      },
      computed: {
      },
      metaInfo() {
           if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;

                let title = 'Radio streaming for '+this.radioStation;
                let description = 'Live radio streaming player for stattion '+this.radioStation;
                let keywords = 'Live radio streaming player '+this.radioStation;

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: ''}
                    ],
                }
            }

      },
      methods: {}
    }

</script>
<style scoped>
@import '~@/assets/scss/audio.scss';
</style>